define([
],
function (
) {
    'use strict';
    const getter = (dataPath, {serviceTopology}) => {
        const baseUrl = serviceTopology.scriptsLocationMap['universal-editor-app'];

        return `${baseUrl}/editorApp.bundle.min.js`;
    };

    return {universalEditorApp: getter};
});
