define([
    'lodash',
    'platformInit/utils/wixCodeUrlUtils'
],
function (
    _,
    wixCodeUrlUtils
) {
    'use strict';
    const getter = (dataPath, {clientSpec, serviceTopology, currentUrl}) => {
        const {wixCodeInstanceId, wixCodeGridId} = clientSpec.appFields.platform.studio;

        const queryParams = currentUrl ? wixCodeUrlUtils.parseQueryParams(currentUrl) : {};
        const bundlerUrlOverrideQueryParam = queryParams['app-studio-bundler-override'];
        const baseUrl = bundlerUrlOverrideQueryParam || `https://${wixCodeInstanceId}.static.pub.${serviceTopology.wixCloudBaseDomain}/static/v2`;

        return `${baseUrl}/${wixCodeGridId}/${wixCodeInstanceId}/appstudio.viewer.js?module-name=bundle`;
    };

    return {appStudioBundler: getter};
});
