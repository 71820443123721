define([
    'lodash',
    'platformInit/utils/wixCodeUrlUtils'
],
function (
    _,
    wixCodeUrlUtils
) {
    'use strict';

    const WIX_CODE_APP_DEF_ID = '675bbcef-18d8-41f5-800e-131ec9e08762';
    const getWixCodeInstanceId = clientSpecMap => _.find(clientSpecMap, {appDefinitionId: WIX_CODE_APP_DEF_ID}).instanceId;

    const getter = (dataPath, {rendererModel, clientSpecMap, serviceTopology, currentUrl}) => {
        if (!rendererModel) {
            return '';
        }

        const wixCodeGridId = rendererModel.wixCodeModel.appData.codeAppId;
        const wixCodeInstanceId = getWixCodeInstanceId(clientSpecMap);
        const queryParams = currentUrl ? wixCodeUrlUtils.parseQueryParams(currentUrl) : {};
        const bundlerUrlOverrideQueryParam = queryParams['app-studio-bundler-override'];
        const baseUrl = bundlerUrlOverrideQueryParam || `https://${wixCodeInstanceId}.static.pub.${serviceTopology.wixCloudBaseDomain}/static/v2`;

        return `${baseUrl}/${wixCodeGridId}/${wixCodeInstanceId}/appstudio.viewer.js?module-name=bundle`;
    };

    return {appBuilderPreviewBundler: getter};
});
