define(['lodash',
    'platformInit/utils/dataGetters/serviceTopologyDataGetter',
    'platformInit/utils/dataGetters/clientSpecDataGetter',
    'platformInit/utils/dataGetters/appStudioBundlerDataGetter',
    'platformInit/utils/dataGetters/universalEditorAppDataGetter',
    'platformInit/utils/dataGetters/appBuilderPreviewBundlerDataGetter'],
function (_) {
    'use strict';

    const dataGetters = _.drop(arguments, 1);

    return _.assign({}, ...dataGetters);
});
