define([
    'platformInit/api/wixCodeAppApi',
    'platformInit/api/initMainR',
    'platformInit/utils/specMapUtils',
    'platformInit/utils/appsUtils',
    'platformInit/utils/widgetsPreLoader',
    'host-worker-init',
    'platformInit/utils/wixCodeUrlUtils'
    // should not require any other package
], function (wixCodeAppApi, initMainR, specMapUtils, appsUtils, widgetsPreLoader, hostWorkerInit, wixCodeUrlUtils) {
    'use strict';

    const {messageProxy} = hostWorkerInit;
    return {
        getAppApi: wixCodeAppApi.getApi,
        initMainR,
        specMapUtils,
        appsUtils,
        messageProxy,
        widgetsPreLoader,
        registerDeps: widgetsPreLoader.registerDeps,
        wixCodeUrlUtils
    };
});
