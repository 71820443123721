define(['lodash', 'platformInit/utils/cookieUtils'], function (_, cookieUtils) {
    'use strict';

    function getScriptEntry(widget) {
        const userScriptDetails = widget.appConfig.userScript;

        return {
            id: widget.id,
            url: userScriptDetails.url,
            scriptName: userScriptDetails.scriptName,
            displayName: userScriptDetails.displayName,
            routerData: widget.routerData
        };
    }

    function getUserCodeUrlsDetails(widgets, rootId) {
        const urls = [];

        const pageWidget = _.find(widgets, widget => widget.id === rootId && widget.type !== 'masterPage');

        const masterPageWidget = _.find(widgets, {id: rootId, type: 'masterPage'});

        if (masterPageWidget) {
            urls.push(getScriptEntry(masterPageWidget));
        }

        if (pageWidget) {
            urls.push(getScriptEntry(pageWidget));
        }

        return urls;
    }

    function getAppConfig(widgets) {
        return _(widgets)
            .map('appConfig')
            .find();
    }

    function parseQueryParams(url) {
        const hashes = url.slice(url.indexOf('?') + 1).split('&');
        const params = {};
        hashes.forEach(hash => {
            const [key, val] = hash.split('=');
            params[key] = decodeURIComponent(val);
        });

        return params;
    }

    function getQueryParameters(params) {
        return _.reduce(params, function (query, value, key) {
            return _.isUndefined(value) ? query : query.concat([`${key}=${value}`]);
        }, []).join('&');
    }

    function getSiteBaseUrl(siteModel, clientSpecMap) {
        if (siteModel.publicModel) {
            const baseUrl = _.trimEnd(siteModel.publicModel.externalBaseUrl, '/');
            return `${baseUrl}/_api/wix-code-public-dispatcher/siteview`;
        }

        const appDef = _.find(clientSpecMap, {type: 'siteextension'});
        const subDomainPrefix = `//${appDef.instanceId}.dev.`;
        const cloudBaseDomain = siteModel.serviceTopology.wixCloudBaseDomain;
        return subDomainPrefix + cloudBaseDomain;
    }

    function getElementoryArguments(siteModel, clientSpecMap, widgets, viewMode) {
        const wixCodeAppConfig = getAppConfig(widgets);
        if (wixCodeAppConfig) {
            const baseUrl = getSiteBaseUrl(siteModel, clientSpecMap);
            return {
                baseUrl,
                queryParameters: getQueryParameters({
                    gridAppId: wixCodeAppConfig.gridAppId,
                    instance: wixCodeAppConfig.instance,
                    viewMode
                }),
                options: {
                    headers: {
                        'X-XSRF-TOKEN': cookieUtils.getCookie('XSRF-TOKEN')
                    }
                }
            };
        }
    }

    function buildScriptsSources(serviceTopology, options = {}) {
        const namespacesSdkSource = `${_.trimEnd(serviceTopology.scriptsLocationMap['wixcode-namespaces'], '/')}/${
            options.isDebug ? 'wixcode-namespaces.js' : 'wixcode-namespaces.min.js'}`;
        const externalComponentsSource = `${_.trimEnd(serviceTopology.scriptsLocationMap['wix-ui-santa'], '/')}/wixcode/${
            options.isDebug ? 'wixcode-components.js' : 'wixcode-components.min.js'}`;
        const wixCodeNamespacesAndElementorySupportSource = `${_.trimEnd(serviceTopology.scriptsLocationMap['wix-code-platform'], '/')}/wixCodeNamespacesAndElementorySupport.min.js`;
        const wixCodeViewerAppSource = `${_.trimEnd(serviceTopology.scriptsLocationMap['wix-code-viewer-app'], '/')}/app.js`;

        return {namespacesSdkSource, externalComponentsSource, wixCodeNamespacesAndElementorySupportSource, wixCodeViewerAppSource};
    }

    return {
        getUserCodeUrlsDetails,
        getElementoryArguments,
        parseQueryParams,
        buildScriptsSources
    };
});
