define([
    'lodash',
    'platformInit/utils/urlResolver'
], function (_, urlResolver) {
    'use strict';

    function getAppSpec(clientSpecMap, appDefinitionId) {
        return _.find(clientSpecMap, {appDefinitionId}) || _.find(clientSpecMap, {type: 'siteextension'});
    }

    const resolveEditorScriptUrl = (clientSpec, dataSources) => {
        const platformFields = _.get(clientSpec, ['appFields', 'platform']);
        if (!_.isUndefined(platformFields)) {
            const url = platformFields.editorScriptUrl;
            if (!_.isUndefined(url)) {
                const resolvedUrl = urlResolver.resolve(url, dataSources);

                return _.merge({}, clientSpec, {
                    appFields: {
                        platform: {
                            editorScriptUrl: resolvedUrl
                        }
                    }
                });
            }
        }

        return clientSpec;
    };

    return {
        getAppSpec,
        resolveEditorScriptUrl,
        resolveUrl: urlResolver.resolve,
        validateUrl: urlResolver.validate
    };
});
